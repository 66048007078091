

.text {
    font-size: 20px;
}  

.userName   {
    padding-left: 8vh;

}

.avatar {
    float: left;

}
.flex2 {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}
.flex   {
    margin-top: 8px;
}
@media screen and (min-width: 900px) {
    .flex {
        display: flex;
        justify-content: center;
    }
    .image  {
        max-width: 100%;
        height: 85vh;
        
            
        }
    .userName{
        font-size: 24px;
    }
    
        .text {
            max-width: 55vh;
            font-size: 30px;
        }    
}
@media screen and (max-width: 900px) {
    .image  {

        max-width: 100%;
        max-height: 70vh;
        margin-left: auto;
        margin-right: auto;
        
    }
    .userName{
        font-size: 2.5vh;
    }
    .flex2{
        margin-top: 6px;
    }

}